// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCoy74FZbhRLd6NW5F5YLo_IZbx1BPr4vQ",
  authDomain: "alankrit-decors.firebaseapp.com",
  projectId: "alankrit-decors",
  storageBucket: "alankrit-decors.appspot.com",
  messagingSenderId: "648246482676",
  appId: "1:648246482676:web:b25f0f8996014cd815b0a1",
  measurementId: "G-CQBKS571C6",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
