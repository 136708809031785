import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Image } from "antd";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { app } from "../firebase";
import { useEffect, useState } from "react";

const Gallery = () => {
  const [itemData, setItemData] = useState([]);
  const storage = getStorage(app);

  // Create a reference under which you want to list
  const listRef = ref(storage, "");

  // Find all the prefixes and items.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await listAll(listRef);
        const imageUrls = [];
        for (const itemRef of res.items) {
          const url = await getDownloadURL(ref(storage, itemRef.fullPath));
          imageUrls.push(url);
        }
        setItemData(imageUrls);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <section id="Gallery">
      <h2 className="mt-4 mb-5 text-center text-3xl font-bold text-gray-900 sm:text-4xl xl:text-4xl font-pj">
        Gallery 🎞️
      </h2>
      <Box
        sx={{
          width: window.width,
          marginBottom: 0,
          paddingLeft: 10,
          paddingRight: 10,
          height: 500,
          overflowY: "scroll",
        }}
      >
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item}>
              <Image srcSet={`${item}`} src={`${item}`} alt="" loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </section>
  );
};
export default Gallery;
