import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { message as mess } from "antd";
import "./ContactStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    // e.preventDefault();
    // console.log(name, email, message);
    // emailjs
    //   .sendForm(
    //     "Vidya_Niranjan_1234",
    //     "template_v8r482p",
    //     e.target,
    //     "Mh5vAI2Js-dM4j3SI"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       // mess.success("Message Sent!");
    //       alert("Message Sent!");
    //       // clearState();
    //       setState({ name: "", email: "", message: "" });
    //       e.target.reset();
    //     },
    //     (error) => {
    //       console.log(error.text);
    //       // message = "Error occured, please try again later"
    //       // mess.error("Error occured, please try again later");
    //       alert("Error occured, please try again later");
    //       setState({ name: "", email: "", message: "" });
    //     }
    //   );
  };
  return (
    <div id="Contact">
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Phone Number"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            {/* <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                +91 99454 65657
              </p>
            </div> */}
            {/* <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                vidya.n@rvce.edu.in
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
