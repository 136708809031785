import "./App.css";
import HomeScreen from "./Pages/HomeScreen";
import Header from "./Components/__/Header";
import Card from "./Components/Card";
import { Contact } from "./Components/Contact";
import Gallery from "./Components/Gallery";
import Testimony from "./Components/Testimony";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import CallIcon from "@mui/icons-material/Call";

const fabStyle = {
  position: "fixed",
  backgroundImage: "linear-gradient(to right, #A1FFCE, #B6FFDB, #C9FFE5)",
  bottom: 16,
  right: 16,
};
const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth", // Use smooth scrolling behavior
  });
};

function App() {
  return (
    <>
      <Fab
        onClick={scrollToBottom}
        size="medium"
        sx={fabStyle}
        aria-label="contact"
      >
        <CallIcon />
      </Fab>
      <Header />
      <HomeScreen />
      <Card />
      <Gallery />
      <Testimony />
      <Contact />
    </>
  );
}

export default App;
